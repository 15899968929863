import BlockContent from '@sanity/block-content-to-react';
import React, { useState } from 'react';

import { clsx, slugify } from '@digital-spiders/misc-utils';
import { MdPlayCircleOutline } from 'react-icons/md';
import EmbedModal from './components/ui/EmbedModal';
import ModalOverlay from './components/ui/ModalOverlay';
import TextLink from './components/ui/TextLink';
import Video from './components/ui/Video';
import { VIDEO_TYPES_MAP } from './constants';
import * as styles from './serializers.module.scss';
import { PageDocumentType } from './types/types';
import { getUrlFromVersatileLink } from './utils/sanity';

function getTextFromNode(node: React.ReactNode): string {
  if (typeof node === 'string') {
    return node;
  }
  if (!Array.isArray(node)) {
    if (node === null || typeof node !== 'object') {
      return '';
    }
    return getTextFromNode(node['props']?.node?.children || '');
  }
  return node.map(getTextFromNode).join('');
}

export const WebsiteInternalLinkSerializer = (props: {
  mark: {
    reference: {
      slug: { current: string };
      _type: PageDocumentType;
    };
    anchorLink?: string;
  };
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <GenericLinkSerializer
      mark={{
        url: getUrlFromVersatileLink({
          pageReference: props.mark.reference,
          anchorLink: props.mark.anchorLink,
        }),
      }}
    >
      {props.children}
    </GenericLinkSerializer>
  );
};

const GenericLinkSerializer = (props: {
  mark: {
    url: string;
  };
  children: React.ReactNode;
}): React.ReactElement => {
  const linkText = getTextFromNode(props.children);
  let linkSizeClass = styles.smallLink;
  if (linkText.length > 20) {
    linkSizeClass = styles.mediumLink;
  }
  if (linkText.length > 60) {
    linkSizeClass = styles.largeLink;
  }

  const isVideo = !!Object.values(VIDEO_TYPES_MAP).find(({ formatRegex }) =>
    props.mark.url.match(formatRegex),
  )?.type;

  const [videoModalOpen, setVideoModalOpen] = useState(false);

  if (isVideo) {
    return (
      <TextLink
        styleOnly
        onClick={() => setVideoModalOpen(true)}
        className={clsx(styles.link, linkSizeClass, styles.inlineVideo)}
      >
        <MdPlayCircleOutline aria-label="video-play" role="img" /> {props.children}
        <ModalOverlay open={videoModalOpen} onClose={() => setVideoModalOpen(false)}>
          <EmbedModal url={props.mark.url} />
        </ModalOverlay>
      </TextLink>
    );
  } else {
    return (
      <TextLink to={props.mark.url} className={clsx(styles.link, linkSizeClass)}>
        {props.children}
      </TextLink>
    );
  }
};

const BlockSerializer = (props: any): React.ReactElement => {
  const slug = slugify(props.node.children.map(child => child.text).join(''));
  if (props.node.style === 'h2') {
    return <h2 id={slug}>{props.children}</h2>;
  }
  if (props.node.style === 'h3') {
    return <h3 id={slug}>{props.children}</h3>;
  }
  if (props.node.style === 'h4') {
    return <h4 id={slug}>{props.children}</h4>;
  }
  if (props.node.style === 'h5') {
    return <h5 id={slug}>{props.children}</h5>;
  }
  return BlockContent.defaultSerializers.types.block(props);
};

const ListSerializer = (props: {
  type: string;
  level: number;
  children: React.ReactNode;
}): React.ReactElement => {
  if (props.type === 'number' && props.level === 2) {
    return <ol type="a">{props.children}</ol>;
  }
  return BlockContent.defaultSerializers.list(props);
};

const VideoSerializer = (props: {
  node: { url: string; isAVerticalVideo?: boolean };
}): React.ReactElement => {
  return (
    <Video
      url={props.node.url}
      isAVerticalVideo={props.node.isAVerticalVideo}
      config={{
        options: {
          playerColor: 'da0b0b',
        },
      }}
    />
  );
};

const EmbedVideoSerializer = (props: { node: { url: string } }): React.ReactElement => {
  return <EmbedModal url={props.node.url} />;
};

const LinkToDownloadFileSerializer = (props: {
  children: any;
  serializerContext: string;
}): React.ReactElement => {
  const fileUrl = props.serializerContext;
  return (
    <a href={fileUrl} download>
      {props.children}
    </a>
  );
};

export const getSerializers = (context?: any) => ({
  marks: {
    websiteInternalLink: props => (
      <WebsiteInternalLinkSerializer {...props} serializerContext={context} />
    ),
    genericLink: props => <GenericLinkSerializer {...props} serializerContext={context} />,
    linkToDownloadFile: props => (
      <LinkToDownloadFileSerializer {...props} serializerContext={context} />
    ),
  },
  types: {
    video: props => <VideoSerializer {...props} serializerContext={context} />,
    block: props => <BlockSerializer {...props} serializerContext={context} />,
    'hp-website-video': props => <EmbedVideoSerializer {...props} serializerContext={context} />,
  },
  list: ListSerializer,
});

const serializers = getSerializers();

export default serializers;
