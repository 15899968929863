import React, { createContext, useContext, useState } from 'react';

export interface GlobalState {
  isEmbedded: boolean;
  setIsEmbedded: (isEmbedded: boolean) => void;
}

// We assume this GlobalStateContext will never be used unless inside
// the GlobalStateContext.Provider, and so the default is never used.
export const GlobalStateContext = createContext<GlobalState>({} as GlobalState);

export const useGlobalState = () => useContext(GlobalStateContext);

interface StateProviderProps {
  children: React.ReactNode;
}

export function GlobalStateProvider({ children }: StateProviderProps) {
  const [isEmbedded, setIsEmbedded] = useState(false);

  const globalState: GlobalState = {
    isEmbedded,
    setIsEmbedded,
  };

  return <GlobalStateContext.Provider value={globalState}>{children}</GlobalStateContext.Provider>;
}
