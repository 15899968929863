import React from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { CASE_STUDIES_PREFIX_URL } from '../../../constants';
import { CaseStudy } from '../../../templates/CaseStudyPage';
import { getPortableTextAsString } from '../../../utils/sanity';
import { clsx, truncateText, urlJoin } from '@digital-spiders/misc-utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import ButtonLink from '../ButtonLink';
import CaseStudyBlock from '../CaseStudyBlock';
import CategoryTag from '../CategoryTag';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import SmartLink from '../SmartLink';
import * as styles from './FeaturedCaseStudiesModule.module.scss';

export interface FeaturedCaseStudiesModuleProps {
  backgroundColor: ModuleBackgroundColor;
  title?: string;
  caseStudies: Array<CaseStudy>;
  moduleLayout: 'row' | 'grid' | 'list';
  showExploreCaseStudiesButton?: boolean;
  className?: string;
}

interface QueryData {
  allSanityCaseStudy: {
    nodes: Array<CaseStudy>;
  };
}

export function getModuleBgColor(props: FeaturedCaseStudiesModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function FeaturedCaseStudiesModule(
  props: FeaturedCaseStudiesModuleProps & CommonModuleProps,
): React.ReactElement {
  const {
    title,
    caseStudies,
    moduleLayout,
    showExploreCaseStudiesButton,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  function renderCaseStudy(caseStudy: CaseStudy, i: number, withShorterHeight?: boolean) {
    return (
      <SmartLink
        to={urlJoin(CASE_STUDIES_PREFIX_URL, caseStudy.slug.current)}
        className={clsx(styles.caseStudyContainer, withShorterHeight && styles.shorterHeight)}
        key={i}
      >
        <div className={styles.backgroundImage}>
          <div className={styles.overlay}></div>
          {moduleLayout === 'grid' && (
            <Image image={caseStudy.image} alt={caseStudy.title} width={1360} height={1440} cover />
          )}
          {moduleLayout === 'row' && (
            <Image image={caseStudy.image} alt={caseStudy.title} width={900} height={970} cover />
          )}
        </div>
        {caseStudy.category && (
          <CategoryTag categoryTitle={caseStudy.category.title} className={styles.category} />
        )}
        <div className={styles.textContainer}>
          <div className={styles.caseStudySpan}>
            <div className={styles.line}></div>
            <span className={styles.caseStudySpan}> Case study</span>
          </div>
          <h3 className={styles.name}>{caseStudy.title}</h3>
          <div className={styles.nameDivider}></div>
          <p className={styles.results}>
            {truncateText(getPortableTextAsString(caseStudy._rawDescription), 4 * 45)}
          </p>
          <div className={styles.link}>
            View case{' '}
            <BsArrowRightShort className={styles.arrow} aria-label="go-arrow" role="img" />
          </div>
        </div>
      </SmartLink>
    );
  }

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      title={title}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      childrenClassName={styles.contentContainer}
    >
      <div
        className={clsx(styles.caseStudiesContainer, moduleLayout === 'grid' && styles.gridLayout)}
      >
        {moduleLayout === 'grid' && (
          <div className={styles.gridContainer}>
            <div className={styles.firstColumnContainer}>
              {caseStudies.slice(0, 1).map((caseStudy, i) => renderCaseStudy(caseStudy, i))}
            </div>
            <div className={styles.secondColumnContainer}>
              {caseStudies.slice(1, 3).map((caseStudy, i) => renderCaseStudy(caseStudy, i, true))}
            </div>
          </div>
        )}
        {moduleLayout === 'row' && caseStudies.map((caseStudy, i) => renderCaseStudy(caseStudy, i))}
        {moduleLayout === 'list' && (
          <div className={styles.listContainer}>
            {caseStudies.map((caseStudy, i) => (
              <CaseStudyBlock caseStudy={caseStudy} />
            ))}
          </div>
        )}
      </div>
      {showExploreCaseStudiesButton && (
        <ButtonLink
          outlined
          to={{ url: urlJoin(CASE_STUDIES_PREFIX_URL) }}
          linkClassName={styles.button}
        >
          Explore our case studies
        </ButtonLink>
      )}
    </ModuleLayout>
  );
}

export default FeaturedCaseStudiesModule;
