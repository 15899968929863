import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';

import { clsx } from '@digital-spiders/misc-utils';
import { sortBy } from '@digital-spiders/nodash';
import { BsCheckSquare, BsExclamationSquare } from 'react-icons/bs';
import Button from '../components/ui/Button';

type FieldValue = string | number | boolean | Array<string>;
export type Field =
  | GenericField<string>
  | GenericField<number>
  | GenericField<boolean>
  | GenericField<Array<string>>;
type SubmitState = 'ready' | 'submitting' | 'submitted';

export type GenericField<T extends FieldValue> = {
  value: T | null;
  setValue: React.Dispatch<React.SetStateAction<T | null>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  validations?: Readonly<Array<'required' | 'email' | ((value: T | null) => string | null)>>;
};

export class FormError extends Error {
  constructor(message: string, readonly formMessage: string, readonly isUserError?: boolean) {
    super(message);
  }
}

export function useFormField<T extends FieldValue>(
  initialValue: T | null,
  validations: Readonly<Array<'required' | 'email' | ((value: T | null) => string | null)>>,
): GenericField<T> {
  const [value, setValue] = useState<T | null>(initialValue);
  const [error, setError] = useState<string>('');

  return {
    value,
    setValue,
    error,
    setError,
    validations,
  };
}

export function useForm<FieldName extends string>({
  fieldsByName,
  onSubmit,
  translateFunction = (key, defaultText) => defaultText,
}: {
  fieldsByName: Record<FieldName, Field>;
  onSubmit: () => boolean | Promise<boolean>;
  translateFunction?: (
    key:
      | 'form.required_field_error'
      | 'form.invalid_email_error'
      | 'form.network_error'
      | 'form.unknown_error'
      | 'form.success_message',
    defaultText: string,
  ) => string;
}): {
  getFieldProps: typeof getFieldProps;
  renderSubmitButton: typeof renderSubmitButton;
  renderFormMessage: typeof renderFormMessage;
  resetState: typeof resetState;
  submitState: SubmitState;
  onFieldUnfocus: typeof onFieldUnfocus;
} {
  const fields = sortBy(
    Object.entries(fieldsByName) as Array<[FieldName, GenericField<FieldValue>]>,
    ([key]) => key,
  ).map(([, field]) => field);

  const [formMessage, setFormMessage] = useState('');
  const [hasFormError, setHasFormError] = useState(false);
  const [submitState, setSubmitState] = useState<SubmitState>('ready');

  function getFieldErrorMsg<T extends FieldValue>(field: GenericField<T>): string | null {
    const { value, validations } = field;
    if (!validations) {
      return null;
    }
    for (const validation of validations) {
      if (typeof validation === 'function') {
        const errorMessage = validation(value);
        if (errorMessage !== null) {
          return errorMessage;
        }
      } else {
        switch (validation) {
          case 'required':
            if (
              typeof value === 'number'
                ? value === null
                : !value || (Array.isArray(value) && value.length === 0)
            ) {
              return translateFunction('form.required_field_error', 'This field is required.');
            }
            break;
          case 'email':
            if (typeof value === 'string' && !value.match(/^\s*[^@\s]+@[^@\s]+\.[^@\s]+\s*$/)) {
              return translateFunction('form.invalid_email_error', 'Invalid email format');
            }
            break;
        }
      }
    }
    return null;
  }

  function onFieldUnfocus<T extends FieldValue>(field: GenericField<T>): void {
    const errorMsg = getFieldErrorMsg(field);
    if (errorMsg !== null) {
      field.setError(errorMsg);
    }
  }

  function checkFormHasErrors(): boolean {
    for (const field of fields) {
      if (getFieldErrorMsg(field) !== null) {
        return true;
      }
    }
    return false;
  }

  async function internalOnSubmit(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    setFormMessage('');
    setHasFormError(false);
    if (submitState !== 'ready') {
      return;
    }

    if (checkFormHasErrors()) {
      for (const field of fields) {
        const errorMsg = getFieldErrorMsg(field);
        if (errorMsg !== null) {
          field.setError(errorMsg);
        }
      }
      return;
    }

    setSubmitState('submitting');

    try {
      const success = await onSubmit();
      if (success) {
        setSubmitState('submitted');
        setFormMessage(
          translateFunction(
            'form.success_message',
            "Thank you for your message, we'll contact you shortly.",
          ),
        );
      } else {
        setSubmitState('ready');
      }
    } catch (error) {
      setSubmitState('ready');
      setHasFormError(true);
      if (
        error &&
        typeof error === 'object' &&
        (error as { message: string }).message === 'Failed to fetch'
      ) {
        setFormMessage(
          translateFunction('form.network_error', 'Network failed to send your request.'),
        );
      } else if (error.formMessage) {
        setFormMessage(error.formMessage);
        if (!error.isUserError) {
          console.error(error);
          Sentry.captureException(error);
        }
      } else {
        setFormMessage(translateFunction('form.unknown_error', 'An unknown error has occurred.'));
        console.error(error);
        Sentry.captureException(error);
      }
    }
  }

  function renderSubmitButton({
    id,
    labels,
    btnClasses,
    iconClasses,
    childrenBefore,
    childrenAfter,
  }: {
    id?: string;
    labels: {
      ready: string;
      submitting: string;
      submitted: string;
    };
    btnClasses?: {
      common?: string;
      ready?: string;
      submitting?: string;
      submitted?: string;
    };
    iconClasses?: {
      common?: string;
      ready?: string;
      submitting?: string;
      submitted?: string;
    };
    childrenBefore?: React.ReactNode;
    childrenAfter?: React.ReactNode;
  }) {
    const btnClass = btnClasses && clsx(btnClasses.common, btnClasses[submitState]);
    const iconClass = iconClasses && clsx(iconClasses.common, iconClasses[submitState]);
    return (
      <Button
        id={id}
        className={btnClass}
        type="submit"
        tabIndex={0}
        onClick={e => internalOnSubmit(e)}
        withoutIconAnimation
      >
        {childrenBefore}
        {iconClass && <i className={iconClass}></i>}
        {labels[submitState]}
        {childrenAfter}
      </Button>
    );
  }

  function renderFormMessage({
    styles,
  }: {
    styles: {
      formMessage?: string;
      formMessageSuccess?: string;
      formMessageError?: string;
    };
  }) {
    if (!formMessage) {
      return null;
    }
    return (
      <div
        className={clsx(
          styles.formMessage,
          formMessage && !hasFormError && styles.formMessageSuccess,
          formMessage && hasFormError && styles.formMessageError,
        )}
      >
        {hasFormError && <BsExclamationSquare />}
        {formMessage && !hasFormError && <BsCheckSquare />}
        <span>{formMessage}</span>
      </div>
    );
  }

  function getFieldProps<T extends FieldValue>(
    field: GenericField<T>,
    options?: {
      setValuePreprocessor?: (value: T | null) => T | null;
      defaultHelperText?: string;
    },
  ): {
    disabled: boolean;
    value: T | null;
    onChange: (value: T | null) => void;
    onBlur: () => void;
    error: boolean;
    helperText: string;
  } {
    return {
      disabled: submitState === 'submitted',
      value: field.value,
      onChange: (value: T | null) => {
        field.setValue(
          options && options.setValuePreprocessor ? options.setValuePreprocessor(value) : value,
        );
        field.setError('');
      },
      onBlur: () => onFieldUnfocus(field),
      error: !!field.error,
      helperText: field.error || (options && options.defaultHelperText) || '',
    };
  }

  function resetState() {
    setFormMessage('');
    setHasFormError(false);
    setSubmitState('ready');
  }

  useEffect(
    () => {
      setFormMessage('');
      setHasFormError(false);
    },
    fields.map(field => field.value),
  );

  return {
    getFieldProps,
    renderSubmitButton,
    renderFormMessage,
    submitState,
    resetState,
    onFieldUnfocus,
  };
}
