import { clsx, slugify } from '@digital-spiders/misc-utils';
import { uniqBy } from '@digital-spiders/nodash';
import React from 'react';
import { ButtonLinkType } from '../../../graphql-fragments/ButtonLink';
import { PageDocument, RawPortableText } from '../../../types/types';
import { useCloser } from '../../../utils/projectUtils';
import { getPortableTextAsString } from '../../../utils/sanity';
import { replaceNewLinesWithBr, wrapSquareBracketedWithEm } from '../../../utils/utils';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import Calendar from '../Calendar';
import Form, { FormField, FormFieldWithId } from '../Form';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import { Closer } from './CloserModule';
import * as styles from './FormModule.module.scss';

export type FormModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  formType: 'manualInputs' | 'scheduleOnce';
  title?: string;
  titleWithCloserParameter?: string;
  subtitle?: string;
  text?: string;
  className?: string;
} & (
  | {
      formType: 'manualInputs';
      form: {
        fields: Array<FormField>;
        submitButtonText?: string;
        thankYouScreen: {
          title: string;
          subtitle?: string;
          _rawText?: RawPortableText;
          scheduleACallButton?: ButtonLinkType;
        };
      };
      pageToGoAfterSubmit?: never;
    }
  | {
      formType: 'scheduleOnce';
      form?: never;
      pageToGoAfterSubmit: PageDocument;
    }
);

interface QueryData {
  allSanityCloser: {
    nodes: Array<Closer>;
  };
}

export function getModuleBgColor(props: FormModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function FormModule(props: FormModuleProps & CommonModuleProps): React.ReactElement {
  const {
    backgroundColor,
    formType,
    title,
    titleWithCloserParameter,
    subtitle,
    text,
    form,
    pageToGoAfterSubmit,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  const formFieldsWithIds: Array<FormFieldWithId> =
    formType === 'manualInputs'
      ? form.fields.map(formField => ({
          id: slugify(
            formField.fieldType === 'singleCheckbox'
              ? getPortableTextAsString(formField._rawText as RawPortableText)
              : formField.title,
          ),
          ...formField,
        }))
      : [];

  if (formFieldsWithIds!.length !== uniqBy(formFieldsWithIds!, 'id').length) {
    throw new Error(
      'Got duplicate ids in formFieldsWithIds: ' +
        JSON.stringify(formFieldsWithIds!.map(formField => formField.id)),
    );
  }

  const closer = useCloser();

  const moduleTitle =
    closer && titleWithCloserParameter
      ? titleWithCloserParameter.replace('{closerName}', closer.name)
      : title;

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      childrenClassName={styles.formContainer}
    >
      {formType === 'scheduleOnce' ? (
        <>
          <div className={styles.textContainer}>
            {closer && (
              <div className={styles.closerContainer}>
                <div className={styles.closerImageContainer}>
                  <Image image={closer.image} />
                </div>
                <div className={styles.closerTextContainer}>
                  <span className={styles.closerSpan}>Meeting with</span>
                  <span className={styles.closerName}>{closer.name}</span>
                </div>
              </div>
            )}
            <div className={styles.titleContainer}>
              {moduleTitle && (
                <h2 className={clsx(styles.title, moduleTitle.length > 40 && styles.smallerFont)}>
                  {wrapSquareBracketedWithEm(moduleTitle)}
                </h2>
              )}
              {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
              <div className={styles.titleDivider}></div>
            </div>
            {text && (
              <p className={clsx(styles.text, text.length > 350 && styles.smallerFont)}>
                {replaceNewLinesWithBr(text)}
              </p>
            )}
          </div>
          <Calendar pageToGoAfterSubmit={pageToGoAfterSubmit} className={styles.calendar} />
        </>
      ) : (
        <Form
          formType="newsletter"
          fields={formFieldsWithIds}
          title={moduleTitle}
          subtitle={subtitle}
          text={text}
          thankYouScreen={form.thankYouScreen}
          className={styles.form}
        />
      )}
    </ModuleLayout>
  );
}

export default FormModule;
