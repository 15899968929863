import { clsx } from '@digital-spiders/misc-utils';
import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React, { useRef } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import PreviewLoadingScreen from '../../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../../preview/previewUtils';
import { usePagination } from '../../../utils/hooks';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import ModuleLayout from '../ModuleLayout';
import ReviewCard, { Review } from './ReviewCard';
import * as styles from './ReviewsModule.module.scss';

export type ReviewsModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  withPagination?: boolean;
  itemsPerPage?: number;
  className?: string;
} & (
  | {
      reviewsToUse: 'all';
      reviews?: never;
    }
  | {
      reviewsToUse: 'chooseManually';
      reviews: Array<Review>;
    }
);

interface QueryData {
  allSanityReview: {
    nodes: Array<Review>;
  };
}

export function getModuleBgColor(props: ReviewsModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function ReviewsModule(props: ReviewsModuleProps & CommonModuleProps): React.ReactElement {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityReview(sort: { date: DESC }) {
        nodes {
          author
          image {
            ...SanityImage
          }
          review
          rating
          source {
            title
            logo {
              ...SanityImage
            }
          }
          category
          date
        }
      }
    }
  `);

  const groqQuery = groq`{
    "allSanityReview": {
      "nodes": *[_type == "review"] | order(date desc) {
        ...
      }
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  const containerRef = useRef<HTMLDivElement>(null);

  const {
    title,
    reviewsToUse,
    reviews,
    withPagination,
    itemsPerPage,
    className,
    moduleId,
    previousModuleBgColor,
  } = props;

  const reviewsToShow =
    reviewsToUse === 'chooseManually' ? reviews : data?.allSanityReview.nodes || [];

  const { currentPageItems, loading, renderPagination } = usePagination(
    reviewsToShow,
    itemsPerPage || 6,
    containerRef,
  );

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      title={title}
    >
      <div className={clsx(styles.reviewsContainer, loading && styles.loading)} ref={containerRef}>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 720: 2, 960: 3 }}>
          <Masonry gutter="24px">
            {(withPagination ? currentPageItems : reviewsToShow).map((review, i) => (
              <ReviewCard key={i} {...review}></ReviewCard>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
      {withPagination && renderPagination()}
    </ModuleLayout>
  );
}

export default ReviewsModule;
