import React from 'react';

import { clsx } from '@digital-spiders/misc-utils';
import * as styles from './SplitText.module.scss';

export type SplitTextProps = {
  children: string;
  className?: string;
  wordClassName?: string;
  charClassName?: string;
  getWordStyle?: ({ word, index }: { word: string; index: number }) => React.CSSProperties;
  getCharStyle?: ({ char, index }: { char: string; index: number }) => React.CSSProperties;
  as?: keyof JSX.IntrinsicElements;
};

const SplitText = ({
  children,
  className,
  wordClassName,
  charClassName,
  getWordStyle,
  getCharStyle,
  as: As = 'div',
}: SplitTextProps): React.ReactElement => {
  let charIndex = 0;
  return (
    <As className={clsx(styles.container, className)}>
      {children.split(/(\s+)/).map((word, i) =>
        i % 2 === 0 ? (
          <span
            key={i}
            className={clsx(styles.word, wordClassName)}
            style={getWordStyle?.({ word, index: i / 2 })}
          >
            {word.split('').map((char, j) => (
              <span
                key={j}
                className={charClassName}
                style={getCharStyle?.({ char, index: charIndex++ })}
              >
                {char}
              </span>
            ))}
          </span>
        ) : (
          word
            .split('')
            .map(char => (charIndex++, char))
            .join('')
        ),
      )}
    </As>
  );
};

export default SplitText;
