import React from 'react';

import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import { useCloser } from '../../../utils/projectUtils';
import { ModuleBackgroundColor } from '../../ModulesContent';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import * as styles from './CloserModule.module.scss';

export interface Closer {
  name: string;
  slug: {
    current: string;
  };
  image: SanityImageType;
  results?: Array<{
    number: string;
    subtitle: string;
  }>;
  testimonial?: {
    testimonial: string;
    author: string;
    position: string;
  };
  scheduleOnceCalendarId: string;
}

export type CloserModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  className?: string;
};

export function getModuleBgColor(props: CloserModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

const CloserModule = ({ title }: CloserModuleProps): React.ReactElement => {
  const closer = useCloser();

  return closer ? (
    <ModuleLayout childrenClassName={styles.contentContainer}>
      <div className={styles.imageContainer}>
        <Image
          image={closer.image}
          sizes={`(max-width: 958px) 100vw, 680px`}
          dimensions={[[680, 680]]}
          cover
        />
      </div>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>
          Meet <em>{closer.name}</em>
        </h2>
        <div className={styles.titleDivider}></div>
        {closer.results && closer.results.length > 0 && (
          <>
            <p className={styles.text}>{closer.name} clients already got more than: </p>
            <div className={styles.resultsContainer}>
              {closer.results.map((result, i) => (
                <div className={styles.resultContainer} key={i}>
                  <span className={styles.resultNumber}>{result.number}</span>
                  <p className={styles.resultSubtitle}>{result.subtitle}</p>
                </div>
              ))}
            </div>
          </>
        )}
        {closer.results && closer.results.length > 0 && closer.testimonial && (
          <div className={styles.divider}></div>
        )}
        {closer.testimonial && (
          <div className={styles.testimonialContainer}>
            <span className={styles.testimonialTitle}>Client testimonial</span>
            <blockquote className={styles.testimonial}>
              "{closer.testimonial.testimonial}"
            </blockquote>
            <div className={styles.testimonialAuthorContainer}>
              <span className={styles.testimonialAuthor}>{closer.testimonial.author}</span>
              <span className={styles.testimonialAuthorPosition}>
                {' - '}
                {closer.testimonial.position}
              </span>
            </div>
          </div>
        )}
      </div>
    </ModuleLayout>
  ) : (
    <></>
  );
};

export default CloserModule;
