import '../styles/main.scss';

import { graphql } from 'gatsby';
import React, { useEffect } from 'react';

import groq from 'groq';
import ModulesContent, { Module, ModuleBackgroundColor } from '../components/ModulesContent';
import PageSEO from '../components/PageSEO';
import Hero, { HeroProps } from '../components/ui/Hero';
import SanityLayout from '../components/ui/SanityLayout';
import CTAModule from '../components/ui/modules/CTAModule';
import { CTA_QUERY_PARAM, HIDE_NAV_QUERY_PARAM } from '../constants';
import { useURLParamsContext } from '../contexts/URLParamsContext';
import { ButtonLinkType } from '../graphql-fragments/ButtonLink';
import { LocalizedSEO } from '../graphql-fragments/seo';
import { withPagePreview } from '../preview/previewUtils';

export const query = graphql`
  fragment Hero on SanityHpWebsiteHero {
    moduleId
    withColoredBackground
    contentLayout
    withCloserSupport
    superTitle
    title
    titleWithCloserParameter
    subtitle
    _rawText(resolveReferences: { maxDepth: 4 })
    image {
      ...SanityImage
    }
    button {
      ...ButtonLink
    }
    reviews
    showClientBrandsLogos
    textAndImageAlignment
    textAlignment
    imageFitOption
    withScheduleOnceCalendar
    videoUrl
  }

  query GetPageById($id: String!) {
    sanityHpWebsitePage(_id: { eq: $id }) {
      title
      slug {
        current
      }
      backgroundColor
      hero {
        ...Hero
      }
      modules {
        ...Modules
      }
      ctaSection {
        title
        text
        hideTextFieldInsteadOfUsingDefault
        button {
          ...ButtonLink
        }
        hideCtaSection
      }
      seo {
        ...SEO
      }
      hideCTAs
      hideNavigation
    }
  }
`;

const groqQuery = groq`{
  "sanityHpWebsitePage": *[_id == $id][0] {
     ...
  }
}`;

interface QueryData {
  sanityHpWebsitePage: {
    title: string;
    slug: {
      current: string;
    };
    backgroundColor: ModuleBackgroundColor;
    hero: HeroProps;
    ctaSection?: {
      title?: string;
      text?: string;
      hideTextFieldInsteadOfUsingDefault?: boolean;
      button?: ButtonLinkType;
      hideCtaSection?: boolean;
    };
    modules: Array<Module>;
    seo: LocalizedSEO;
    hideCTAs?: boolean;
    hideNavigation?: boolean;
  };
}

const Page = withPagePreview<QueryData>(
  {
    groqQuery,
  },
  ({ data }): React.ReactElement => {
    const {
      title,
      slug,
      backgroundColor,
      hero,
      modules,
      ctaSection,
      seo,
      hideCTAs,
      hideNavigation,
    } = data.sanityHpWebsitePage;

    const { ctaParam } = useURLParamsContext();

    useEffect(() => {
      const urlSearchParams = new URLSearchParams(window.location.search);

      if (hideCTAs) {
        // TODO
        urlSearchParams.set(CTA_QUERY_PARAM, 'none');
        //   sessionStorage.setItem(CTA_QUERY_PARAM, 'none');
        // } else if (!window.location.search.includes(CTA_QUERY_PARAM)) {
        //   // Only remove 'cta' if it's not manually set in the URL
        //   urlSearchParams.delete(CTA_QUERY_PARAM);
        //   sessionStorage.removeItem(CTA_QUERY_PARAM);
      }

      if (hideNavigation) {
        urlSearchParams.set(HIDE_NAV_QUERY_PARAM, '');
        // TODO
        //   sessionStorage.setItem(HIDE_NAV_QUERY_PARAM, 'true');
        // } else if (!window.location.search.includes(HIDE_NAV_QUERY_PARAM)) {
        //   // Only remove 'nonav' if it's not manually set in the URL
        //   urlSearchParams.delete(HIDE_NAV_QUERY_PARAM);
        //   sessionStorage.removeItem(HIDE_NAV_QUERY_PARAM);
      }

      const newQuery = urlSearchParams.toString();
      const newUrlPathAndQuery = window.location.pathname + (newQuery ? '?' + newQuery : '');

      history.replaceState(null, '', newUrlPathAndQuery);
    }, []);

    return (
      <SanityLayout pageTheme={backgroundColor}>
        <PageSEO defaultTitle={slug.current === '/' ? '' : title} pageSEO={seo} />
        <Hero {...hero} pageColor={backgroundColor} />
        <ModulesContent modules={modules} pageTheme={backgroundColor} />
        {!ctaSection?.hideCtaSection && ctaParam !== 'none' && (
          <CTAModule
            previousModuleBgColor={
              modules[modules.length - 1].backgroundColor
                ? modules[modules.length - 1].backgroundColor
                : backgroundColor
            }
            title={ctaSection?.title}
            text={
              ctaSection?.text || (ctaSection?.hideTextFieldInsteadOfUsingDefault ? ' ' : undefined)
            }
            button={ctaSection?.button}
          />
        )}
      </SanityLayout>
    );
  },
);

export default Page;
