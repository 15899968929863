import React, { useState } from 'react';

import { clsx, slugify } from '@digital-spiders/misc-utils';
import { useModalForm } from '../../contexts/ModalFormContext';
import { RawPortableText, VersatileLink } from '../../types/types';
import {
  getPortableText,
  getPortableTextAsString,
  getUrlFromVersatileLink,
} from '../../utils/sanity';
import Button, { ButtonProps } from './Button';
import * as styles from './ButtonLink.module.scss';
import { FormField } from './Form';
import SmartLink from './SmartLink';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import TextLink from './TextLink';

export interface ButtonLinkProps extends ButtonProps {
  to: VersatileLink;
  linkClassName?: string;
  modalFormButtonClassName?: string;
}

type FormFieldWithId = {
  id: string;
} & FormField;

const ButtonLink = ({
  to,
  linkClassName,
  modalFormButtonClassName,
  ...restProps
}: ButtonLinkProps): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const additionalFormFields = [
    {
      crmFieldId: 'acceptTheTerms',
      fieldType: 'singleCheckbox',
      isFieldRequired: true,
      textNode: (
        <p>
          I agree to the{' '}
          <TextLink
            styleOnly
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            terms and conditions
          </TextLink>{' '}
        </p>
      ),
      _rawText: getPortableText(['I agree to the terms and conditions']),
    },
    {
      crmFieldId: 'joinTheHookPointNewsletter',
      fieldType: 'singleCheckbox',
      isFieldRequired: false,
      _rawText: getPortableText(['Join The Going Viral Newsletter!']),
    },
  ] as Array<FormField>;

  const formFieldsWithIds: Array<FormFieldWithId> = to.downloadFileFormModal
    ? [...to.downloadFileFormModal.formFields, ...additionalFormFields].map(formField => ({
        id: slugify(
          formField.fieldType === 'singleCheckbox'
            ? getPortableTextAsString(formField._rawText as RawPortableText)
            : formField.title,
        ),
        ...formField,
      }))
    : [];

  const { showModalForm } = useModalForm();

  if (to.downloadFileFormModal && formFieldsWithIds.length > 1) {
    return (
      <>
        <Button
          {...restProps}
          className={clsx(restProps.className, modalFormButtonClassName)}
          onClick={() =>
            showModalForm({
              modalForm: to.downloadFileFormModal,
              formFieldsWithIds,
            })
          }
        ></Button>
        <TermsAndConditionsModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />
      </>
    );
  } else {
    return (
      <SmartLink
        className={clsx(styles.link, linkClassName)}
        to={getUrlFromVersatileLink(to)}
        tabIndex={-1}
      >
        <Button {...restProps}></Button>
      </SmartLink>
    );
  }
};

export default ButtonLink;
