import React, { useContext, useState } from 'react';
import { FaXTwitter } from 'react-icons/fa6';
import { FiLink } from 'react-icons/fi';
import { SlSocialFacebook, SlSocialLinkedin } from 'react-icons/sl';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

import { clsx, urlJoin } from '@digital-spiders/misc-utils';
import { BsCheckSquare } from 'react-icons/bs';
import { CTA_QUERY_PARAM, HIDE_NAV_QUERY_PARAM, SITE_DOMAIN } from '../../constants';
import { LocationContext } from '../../contexts/LocationContext';
import { useURLParamsContext } from '../../contexts/URLParamsContext';
import { withDataLayer } from '../../utils/utils';
import { ModuleBackgroundColor } from '../ModulesContent';
import * as styles from './SocialMediaShare.module.scss';

interface SocialMediaShareProps {
  theme?: ModuleBackgroundColor;
  className?: string;
}

const SocialMediaShare = ({ theme, className }: SocialMediaShareProps): React.ReactElement => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const location = useContext(LocationContext);

  const { ctaParam, noNavParam } = useURLParamsContext();

  const currentUrl = location?.pathname
    ? SITE_DOMAIN +
      urlJoin(
        location.pathname,
        ctaParam !== null ? `?${CTA_QUERY_PARAM}=${ctaParam}` : '',
        noNavParam !== null ? `?${HIDE_NAV_QUERY_PARAM}=${noNavParam}` : '',
      )
    : '';

  async function copyUrlToClipboard() {
    await navigator.clipboard.writeText(currentUrl);
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 3000);
  }

  return (
    <div
      className={clsx(styles.socialMediashareContainer, className)}
      data-theme={theme === 'dark' ? 'dark' : ''}
    >
      <div className={styles.firstRow}>
        <LinkedinShareButton
          url={currentUrl}
          className={styles.linkContainer}
          onClick={() => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'click-share-button', type: 'linkedin' });
            });
          }}
        >
          <SlSocialLinkedin className={styles.icon} aria-label="linkedin-icon" role="img" />
          <span className={styles.visuallyHidden}>LinkedIn</span>
        </LinkedinShareButton>
        <FacebookShareButton
          url={currentUrl}
          className={styles.linkContainer}
          onClick={() => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'click-share-button', type: 'facebook' });
            });
          }}
        >
          <SlSocialFacebook className={styles.icon} aria-label="facebook-icon" role="img" />
          <span className={styles.visuallyHidden}>Facebook</span>
        </FacebookShareButton>
        <TwitterShareButton
          url={currentUrl}
          className={styles.linkContainer}
          onClick={() => {
            withDataLayer(dataLayer => {
              dataLayer.push({ event: 'click-share-button', type: 'twitter' });
            });
          }}
        >
          <FaXTwitter className={styles.icon} aria-label="twitter-icon" role="img" />
          <span className={styles.visuallyHidden}>Twitter</span>
        </TwitterShareButton>
      </div>
      <div className={styles.divider}></div>
      <button
        className={clsx(styles.linkContainer, styles.copyToClipboard)}
        onClick={copyUrlToClipboard}
      >
        <FiLink className={styles.icon} aria-label="copy-link-icon" role="img" />
        <span className={clsx(styles.sucessMessage, !showSuccessMessage && styles.hidden)}>
          <BsCheckSquare /> Copied to clipboard
        </span>
        <span className={styles.visuallyHidden}>Copy to clipboard</span>
      </button>
    </div>
  );
};

export default SocialMediaShare;
