import React, { useContext } from 'react';

import { checkUrlsMatch, clsx } from '@digital-spiders/misc-utils';
import { LocationContext } from '../../contexts/LocationContext';
import { SingleEntryWithSubtitle } from '../../graphql-fragments/navigationMenu';
import nestedArrow from '../../images/nestedArrow.svg';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import * as styles from './MenuEntry.module.scss';
import TextLink from './TextLink';

export interface MenuEntryProps {
  entry: SingleEntryWithSubtitle;
  withLeftArrow?: boolean;
  isSubEntry?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
}

const MenuEntry = ({
  entry,
  withLeftArrow,
  isSubEntry,
  onClick,
  className,
}: MenuEntryProps): React.ReactElement => {
  const location = useContext(LocationContext);

  return (
    <TextLink
      onClick={onClick}
      to={getUrlFromVersatileLink(entry)}
      className={clsx(
        styles.link,
        location &&
          checkUrlsMatch(location.pathname, getUrlFromVersatileLink(entry)) &&
          styles.active,
        isSubEntry && styles.subEntry,
        className,
      )}
    >
      {withLeftArrow && <img src={nestedArrow} alt="" className={styles.arrowIcon} />}
      <div className={styles.titleContainer}>
        <span className={styles.title}>{entry.title}</span>
        {entry.subtitle && <span className={styles.subtitle}>{entry.subtitle}</span>}
      </div>
    </TextLink>
  );
};

export default MenuEntry;
