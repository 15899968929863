import { clsx } from '@digital-spiders/misc-utils';
import React from 'react';
import * as styles from './PreviewIndicator.module.scss';
import indicator from './assets/indicator.svg';

export default function PreviewIndicator() {
  return (
    <div className={clsx(styles.container)} title="Preview Mode">
      <img src={indicator} alt="" className={styles.indicatorIcon}></img>
    </div>
  );
}
