import { parseDate, urlJoin } from '@digital-spiders/misc-utils';
import { sortBy } from '@digital-spiders/nodash';
import { graphql, useStaticQuery } from 'gatsby';
import groq from 'groq';
import React from 'react';
import {
  ARTICLE_PREFIX_URL,
  CASE_STUDIES_PREFIX_URL,
  INSIGHT_PREFIX_URL,
} from '../../../constants';
import { ButtonLinkType } from '../../../graphql-fragments/ButtonLink';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import PreviewLoadingScreen from '../../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../../preview/previewUtils';
import { Article } from '../../../templates/ArticlePage';
import { Insight } from '../../../templates/InsightPage';
import { getPortableTextAsString } from '../../../utils/sanity';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import ButtonLink from '../ButtonLink';
import ItemListing from '../ItemListing';
import ModuleLayout from '../ModuleLayout';
import * as styles from './ArticlesListingModule.module.scss';

export type ArticlesListingModuleProps = {
  backgroundColor: ModuleBackgroundColor;
  title: string;
  showAllArticlesButton?: ButtonLinkType;
  withPagination?: boolean;
  dontUpdatePageOnUrl?: boolean;
  itemsPerPage?: number;
  hideImageOnMobile?: boolean;
  className?: string;
} & (
  | {
      referencesToUse: 'all' | 'latestThree';
      articleReferences?: never;
      articleType?: never;
    }
  | {
      referencesToUse: 'chooseManually';
      articleReferences: Array<Article | Insight>;
      articleType?: 'insight' | 'article';
    }
  | {
      referencesToUse: 'chooseManually';
      articleReferences: Array<{
        _id: string;
        image: SanityImageType;
        title: string;
        slug: { current: string };
        excerpt: string;
        categoryTitle?: string;
      }>;
      articleType: 'caseStudy';
    }
);

interface QueryData {
  allSanityArticle: {
    nodes: Array<Article>;
  };
}

export function getModuleBgColor(props: ArticlesListingModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function ArticlesListingModule(
  props: ArticlesListingModuleProps & CommonModuleProps,
): React.ReactElement {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      allSanityArticle: allSanityHpWebsiteArticle {
        nodes {
          ...Article
        }
      }
    }
  `);

  const groqQuery = groq`{
    "allSanityArticle": {
      "nodes": *[_type == "hp-website-article"] {
        ...
      }
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const {
    title,
    referencesToUse,
    articleReferences,
    showAllArticlesButton,
    className,
    moduleId,
    previousModuleBgColor,
    withPagination,
    dontUpdatePageOnUrl,
    itemsPerPage,
    articleType,
    hideImageOnMobile,
  } = props;

  const allArticlesSorted = sortBy(
    data.allSanityArticle.nodes,
    article => (article.date && parseDate(article.date)) || new Date(article._createdAt),
    'desc',
  );

  const listingItems = (
    referencesToUse === 'chooseManually' && articleReferences
      ? articleReferences
      : referencesToUse === 'latestThree'
      ? allArticlesSorted.slice(0, 3)
      : allArticlesSorted
  ).map(article => {
    return {
      _id: article._id,
      image: article.image,
      title: article.title,
      text: article.excerpt || getPortableTextAsString(article._rawContent),
      category: article.category?.title,
      link: {
        url:
          articleType === 'caseStudy'
            ? urlJoin(CASE_STUDIES_PREFIX_URL, article.slug.current)
            : articleType === 'insight'
            ? urlJoin(INSIGHT_PREFIX_URL, article.slug.current)
            : urlJoin(ARTICLE_PREFIX_URL, article.slug.current),
      },
    };
  });

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      title={title}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      childrenClassName={styles.content}
    >
      <div className={styles.contentContainer}>
        <ItemListing
          items={listingItems}
          withPagination={referencesToUse === 'all' || withPagination}
          dontUpdateUrl={dontUpdatePageOnUrl}
          itemsPerPage={itemsPerPage}
          hideImageOnMobile={hideImageOnMobile}
        />
        {showAllArticlesButton && referencesToUse !== 'all' && (
          <ButtonLink
            outlined
            to={{ url: urlJoin(ARTICLE_PREFIX_URL) }}
            linkClassName={styles.button}
          >
            See all blog articles
          </ButtonLink>
        )}
      </div>
    </ModuleLayout>
  );
}

export default ArticlesListingModule;
