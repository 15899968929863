import React, { useEffect, useRef, useState } from 'react';

import { clsx, slugify } from '@digital-spiders/misc-utils';
import { easeOut, motion, useScroll, useTransform } from 'framer-motion';
import { useWindowDimensions } from '../../utils/hooks';
import { replaceNewLinesWithBr } from '../../utils/utils';
import { ModuleBackgroundColor } from '../ModulesContent';
import * as styles from './ModuleLayout.module.scss';

export interface ModuleLayoutProps {
  id?: string;
  title?: string;
  text?: string;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  childrenClassName?: string;
  previousModuleBgColor?: ModuleBackgroundColor;
  currentModuleBgColor?: ModuleBackgroundColor;
}

export function getModuleId(id?: string, title?: string) {
  return id || (title && slugify(title));
}

const ModuleLayout = ({
  id,
  title,
  text,
  children,
  className,
  contentClassName,
  childrenClassName,
  previousModuleBgColor,
  currentModuleBgColor,
}: ModuleLayoutProps): React.ReactElement => {
  const sectionRef = useRef<HTMLDivElement>(null);

  const [withEntryAnimations, setWithEntryAnimations] = useState(false);
  const { height: windowHeight } = useWindowDimensions();

  // Activate animations only on sections that would start
  // outside the screen if user was at the top of the page.
  useEffect(() => {
    const sectionRect = sectionRef.current?.getBoundingClientRect();
    if (sectionRect && windowHeight && sectionRect.top + window.scrollY > windowHeight) {
      setWithEntryAnimations(true);
    }
  }, []);

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ['start 120%', 'start 50%'],
  });

  const opacityMV = useTransform(scrollYProgress, [0, 1], [0, 1], { ease: easeOut });
  const translateYMV = useTransform(scrollYProgress, [0, 1], ['20vh', '0vh'], { ease: easeOut });
  const titleDeviderWidthMV = useTransform(scrollYProgress, [0.3, 1], [0, 95], { ease: easeOut });

  return (
    <section
      className={clsx(styles.moduleContainer, className)}
      id={getModuleId(id, title)}
      data-theme={
        (currentModuleBgColor === 'dark' && 'dark') ||
        (currentModuleBgColor === 'light' && 'light') ||
        ''
      }
    >
      <motion.div
        className={clsx(styles.contentContainer, contentClassName)}
        ref={sectionRef}
        style={
          withEntryAnimations
            ? {
                opacity: opacityMV,
                translateY: translateYMV,
              }
            : {}
        }
      >
        {(title || text) && (
          <div className={clsx(styles.titleAndTextContainer)}>
            {title && (
              <>
                <h2 className={styles.title}>{replaceNewLinesWithBr(title)}</h2>
                <motion.div
                  className={styles.titleDivider}
                  style={withEntryAnimations ? { width: titleDeviderWidthMV } : {}}
                ></motion.div>
              </>
            )}
            {text && <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>}
          </div>
        )}
        <div className={clsx(styles.children, childrenClassName)}>{children}</div>
      </motion.div>
    </section>
  );
};

export default ModuleLayout;
