import { clsx } from '@digital-spiders/misc-utils';
import * as Sentry from '@sentry/react';
import getYoutubeID from 'get-youtube-id';
import React, { useRef, useState } from 'react';
import { RiPlayFill } from 'react-icons/ri';
import { ReactPlayerProps } from 'react-player';
import { SanityImageType } from '../../graphql-fragments/SanityImage';
import { getVideoTypeByUrl } from '../../utils/projectUtils';
import { Override } from '../../utils/typescript';
import EmbedModal from './EmbedModal';
import Image from './Image';
import ImageWithFallbacks from './ImageWithFallbacks';
import ModalOverlay from './ModalOverlay';
import * as styles from './Video.module.scss';

export type EmbedVideoProps = Override<
  ReactPlayerProps,
  {
    url: string;
    thumbnail?: SanityImageType;
    className?: string;
    isAVerticalVideo?: boolean;
    iconOnTopLeftCorner?: boolean;
    hideVideoViewsInfo?: boolean;
    onVideoClick?: React.MouseEventHandler;
  }
>;

/**
 * Used to get the thumbnail for the video, only tries to handle cases where
 * react-player autofetching is not good enough.
 * Can return either a url, a ReactElement, or null if we don't want to handle it specially.
 */
export function useVideoThumbnail(url: string): string | React.ReactElement | false | true {
  const [thumbnail, setThumbnail] = useState<string | React.ReactElement | false | true>(false);

  if (thumbnail) {
    return thumbnail;
  }

  if (
    url.match(
      /^(https?:\/\/)?(((www\.)?youtube\.com\/(watch\?v=|embed\/|shorts\/))|(youtu\.be\/))[\w-]+$/,
    )
  ) {
    // YOUTUBE
    const youtubeId = getYoutubeID(url);
    if (!youtubeId) {
      throw new Error('Could not extract youtube id from url: ' + url);
    }
    const largeImgUrl = 'https://i.ytimg.com/vi/' + youtubeId + '/maxresdefault.jpg';
    const mediumImgUrl = 'https://i.ytimg.com/vi/' + youtubeId + '/sddefault.jpg';
    const smallImgUrl = 'https://i.ytimg.com/vi/' + youtubeId + '/hqdefault.jpg';

    setThumbnail(
      <ImageWithFallbacks
        className={clsx(styles.youtubeThumbnail, styles.thumbnail)}
        src={largeImgUrl}
        fallbackImage={[mediumImgUrl, smallImgUrl]}
        alt=""
        getConsiderSuccessAsError={e => {
          if (e.path && e.path[0] && (e.path[0].width !== 120 || e.path[0].height !== 90)) {
            return false;
          }
          if (e.target && (e.target.width !== 120 || e.target.height !== 90)) {
            return false;
          }
          return true;
        }}
      ></ImageWithFallbacks>,
    );
  } else if (url.includes('wistia')) {
    // WISTIA
    const wistiaUrlParts = url.split('/');
    const wistiaId = wistiaUrlParts[wistiaUrlParts.length - 1];
    (async () => {
      try {
        const resp = await fetch(
          `https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/${wistiaId}`,
        );
        if (resp.status === 200) {
          const data = await resp.json();
          setThumbnail(data.thumbnail_url);
        } else {
          throw new Error(
            `Got status code ${resp.status} when trying to fetch thumbnail for wistia video ${url}`,
          );
        }
      } catch (err) {
        Sentry.captureException(err);
        // fallback to autofetch, though it won't work
        setThumbnail(true);
      }
    })();
  } else {
    // true means autofetch, used because we don't have a
    // special fetching logic for the type of video used
    setThumbnail(true);
  }

  return thumbnail;
}

function EmbedVideo(props: EmbedVideoProps): React.ReactElement {
  const type = getVideoTypeByUrl(props.url);

  const thumbnail = type === 'youtube' && !props.thumbnail && useVideoThumbnail(props.url);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [modalPositionY, setModalPositionY] = useState(0);

  const isAVerticalVideo = !!(
    props.isAVerticalVideo ||
    (type === 'youtube' && props.url.match(/\/shorts\//))
  );

  return (
    <div
      ref={containerRef}
      className={clsx(styles.container, props.className)}
      onClick={e => {
        if (props.onVideoClick) {
          props.onVideoClick(e);
        }
        const videoCenterY = containerRef.current
          ? containerRef.current.getBoundingClientRect().top +
            containerRef.current.getBoundingClientRect().height / 2
          : null;
        const windowHeight = window.document.documentElement?.getBoundingClientRect()?.height;
        setModalPositionY(videoCenterY ? videoCenterY : windowHeight / 2);
        setVideoModalOpen(true);
      }}
    >
      {!props.thumbnail ? (
        thumbnail
      ) : (
        <div className={styles.imageContainer}>
          <Image image={props.thumbnail} />
        </div>
      )}
      <div className={styles.overlay}></div>
      <div
        className={clsx(
          styles.iconWrapper,
          props.iconOnTopLeftCorner && styles.iconOnTopLeftCorner,
        )}
      >
        <RiPlayFill className={styles.icon} />
      </div>
      <ModalOverlay
        open={videoModalOpen}
        positionY={modalPositionY}
        onClose={() => setVideoModalOpen(false)}
      >
        <EmbedModal
          url={props.url}
          hideVideoViewsInfo={props.hideVideoViewsInfo}
          isAVerticalVideo={isAVerticalVideo}
        />
      </ModalOverlay>
    </div>
  );
}

export default EmbedVideo;
