import 'react-multi-carousel/lib/styles.css';

import React from 'react';
import Carousel from 'react-multi-carousel';

import { clsx, urlJoin } from '@digital-spiders/misc-utils';
import { BsArrowLeft, BsArrowRight, BsArrowRightShort } from 'react-icons/bs';
import { INSIGHT_PREFIX_URL } from '../../constants';
import { Insight } from '../../templates/InsightPage';
import CategoryTag from './CategoryTag';
import Image from './Image';
import * as styles from './InsightCarousel.module.scss';
import SmartLink from './SmartLink';

interface InsightCarouselProps {
  insights: Array<Insight>;
}

const responsive = {
  mobile: {
    breakpoint: { min: 0, max: 720 },
    items: 1,
    partialVisibilityGutter: 65,
  },
  tablet: {
    breakpoint: { min: 720, max: 960 },
    items: 1,
    partialVisibilityGutter: 60,
  },
  tabletHorizontal: {
    breakpoint: { min: 960, max: 1100 },
    items: 1,
    partialVisibilityGutter: 90,
  },
  smallLaptop: {
    breakpoint: { min: 1100, max: 1350 },
    items: 1,
    partialVisibilityGutter: 260,
  },
  laptop: {
    breakpoint: { min: 1350, max: 1750 },
    items: 1,
    partialVisibilityGutter: 350,
  },
  desktop: {
    breakpoint: { min: 1750, max: 3000 },
    items: 1,
    partialVisibilityGutter: 500,
  },
};

const ButtonGroup = ({
  next,
  previous,
  carouselState: { currentSlide, totalItems, slidesToShow },
}: {
  next: () => void;
  previous: () => void;
  carouselState: { currentSlide: number; totalItems: number; slidesToShow: number };
}) => {
  return (
    <div className={styles.buttonGroup}>
      <button
        disabled={currentSlide === 0}
        className={styles.button + ' ' + styles.buttonLeft}
        onClick={() => previous()}
      >
        <BsArrowLeft className={styles.buttonIcon} />
        <div className={styles.visuallyHidden}>Previous</div>
      </button>

      <button
        disabled={currentSlide === totalItems - slidesToShow}
        className={styles.button + ' ' + styles.buttonRight}
        onClick={() => next()}
      >
        <BsArrowRight className={styles.buttonIcon} />
        <div className={styles.visuallyHidden}>Next</div>
      </button>
    </div>
  );
};

const InsightCarousel = ({ insights }: InsightCarouselProps): React.ReactElement => {
  return (
    <Carousel
      arrows={false}
      draggable={false}
      responsive={responsive}
      ssr={true}
      containerClass={styles.carousel}
      // @ts-ignore
      customButtonGroup={<ButtonGroup />}
      renderButtonGroupOutside
      partialVisible
      showDots={false}
      shouldResetAutoplay={false}
      pauseOnHover
    >
      {insights.map((insight, i) => (
        <SmartLink
          to={urlJoin(INSIGHT_PREFIX_URL, insight.slug.current)}
          className={styles.card}
          key={i}
        >
          <div className={styles.textContainer}>
            {insight.category && (
              <div className={styles.categoriesContainer}>
                <CategoryTag key={i} categoryTitle={insight.category.title} />
              </div>
            )}
            <h3 className={styles.title}>{insight.title}</h3>
            <p className={styles.description}>{insight.excerpt}</p>
            <div className={styles.link}>
              View insight <BsArrowRightShort className={styles.arrow} />
            </div>
          </div>
          <div className={clsx(styles.imageContainer, styles.desktop)}>
            <Image image={insight.image} className={styles.image} />
          </div>
        </SmartLink>
      ))}
    </Carousel>
  );
};

export default InsightCarousel;
