import { urlJoin } from '@digital-spiders/misc-utils';
import { ARTICLE_PREFIX_URL, CASE_STUDIES_PREFIX_URL, INSIGHT_PREFIX_URL } from '../constants';

export interface PageCreationData {
  slug: {
    current: string;
  };
  _id: string;
  seo: {
    hideFromSitemap?: boolean;
  };
}

export interface PagesCreationQueryData {
  allSanityHpWebsitePage: {
    nodes: Array<PageCreationData>;
  };
  allSanityHpWebsiteCaseStudy: {
    nodes: Array<PageCreationData>;
  };
  allSanityHpWebsiteArticle: {
    nodes: Array<PageCreationData>;
  };
  allSanityHpWebsiteInsight: {
    nodes: Array<PageCreationData>;
  };
}

export const pagesCreationGraphqlQuery = `
  fragment ContextSEO on SanitySeo {
    hideFromSitemap
  }
  {
    allSanityHpWebsitePage {
      nodes {
        slug {
          current
        }
        _id
        seo {
          ...ContextSEO
        }
      }
    }
    allSanityHpWebsiteCaseStudy {
      nodes {
        slug {
          current
        }
        _id
        seo {
          ...ContextSEO
        }
      }
    }
    allSanityHpWebsiteArticle {
      nodes {
        slug {
          current
        }
        _id
        seo {
          ...ContextSEO
        }
      }
    }
    allSanityHpWebsiteInsight {
      nodes {
        slug {
          current
        }
        _id
        seo {
          ...ContextSEO
        }
      }
    }
  }
`;

const pageCreationDataGroqFragment = `
  slug {
    current
  },
  _id,
  seo {
    ...
  }
`;

export const pagesCreationGroqQuery = `
  {
    "allSanityHpWebsitePage": {
      "nodes": *[_type == "hp-website-page"] {
        ${pageCreationDataGroqFragment}
      }
    },
    "allSanityHpWebsiteCaseStudy": {
      "nodes": *[_type == "hp-website-caseStudy"] {
        ${pageCreationDataGroqFragment}
      }
    },
    "allSanityHpWebsiteArticle": {
      "nodes": *[_type == "hp-website-article"] {
        ${pageCreationDataGroqFragment}
      }
    },
    "allSanityHpWebsiteInsight": {
      "nodes": *[_type == "hp-website-insight"] {
        ${pageCreationDataGroqFragment}
      }
    }
  }
  `;

interface PageCreationConfig {
  path: string;
  component: string;
  context?: Record<string, unknown>;
}

export function getPagesToCreate(
  pagesCreationQueryData: PagesCreationQueryData,
): Array<PageCreationConfig> {
  const {
    allSanityHpWebsitePage,
    allSanityHpWebsiteCaseStudy,
    allSanityHpWebsiteArticle,
    allSanityHpWebsiteInsight,
  } = pagesCreationQueryData;

  function getPageBaseContext(page: PageCreationData) {
    return {
      id: page._id,
      hideFromSitemap: !!page.seo?.hideFromSitemap,
    };
  }

  return [
    ...allSanityHpWebsitePage.nodes.map(page => ({
      path: page.slug.current,
      component: './src/templates/Page.tsx',
      context: getPageBaseContext(page),
    })),

    ...allSanityHpWebsiteCaseStudy.nodes
      .filter(caseStudy => caseStudy.slug?.current)
      .map(caseStudy => ({
        path: urlJoin(CASE_STUDIES_PREFIX_URL, caseStudy.slug.current),
        component: './src/templates/CaseStudyPage.tsx',
        context: getPageBaseContext(caseStudy),
      })),

    ...allSanityHpWebsiteArticle.nodes.map(article => ({
      path: urlJoin(ARTICLE_PREFIX_URL, article.slug.current),
      component: './src/templates/ArticlePage.tsx',
      context: getPageBaseContext(article),
    })),

    ...allSanityHpWebsiteInsight.nodes.map(insight => ({
      path: urlJoin(INSIGHT_PREFIX_URL, insight.slug.current),
      component: './src/templates/InsightPage.tsx',
      context: getPageBaseContext(insight),
    })),
  ];
}
