import {
  clsx,
  getEstimatedReadingTimeInMinutes,
  truncateText,
  urlJoin,
} from '@digital-spiders/misc-utils';
import React from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { ARTICLE_PREFIX_URL } from '../../../constants';
import { Article } from '../../../templates/ArticlePage';
import { getPortableTextAsString } from '../../../utils/sanity';
import { CommonModuleProps, ModuleBackgroundColor } from '../../ModulesContent';
import CategoryTag from '../CategoryTag';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import SmartLink from '../SmartLink';
import * as styles from './FeaturedArticlesModule.module.scss';

export interface FeaturedArticlesModuleProps {
  backgroundColor: ModuleBackgroundColor;
  articles: Array<Article>;
  className?: string;
}

export function getModuleBgColor(props: FeaturedArticlesModuleProps): ModuleBackgroundColor {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return props.backgroundColor;
}

function FeaturedArticlesModule(
  props: FeaturedArticlesModuleProps & CommonModuleProps,
): React.ReactElement {
  const { backgroundColor, articles, className, moduleId, previousModuleBgColor } = props;

  function renderArticle(article: Article, index: number) {
    return (
      <SmartLink
        className={clsx(styles.articleContainer, articles.length === 2 && styles.halfWidthArticle)}
        key={index}
        to={urlJoin(ARTICLE_PREFIX_URL, article.slug.current)}
      >
        <div className={styles.imageContainer}>
          <div className={styles.overlay}></div>
          <Image
            image={article.image}
            className={styles.image}
            width={1920}
            height={980}
            cover
            alt={'Illustration of ' + article.title}
          />
        </div>
        {article.category && (
          <CategoryTag categoryTitle={article.category?.title} className={styles.category} />
        )}
        <div className={styles.textContainer}>
          <h3 className={styles.title}>{article.title}</h3>
          <p className={styles.description}>
            {truncateText(article.excerpt || getPortableTextAsString(article._rawContent), 2 * 45)}
          </p>
        </div>
        <div className={styles.linkAndInfoContainer}>
          <div className={styles.link}>
            Know more{' '}
            <BsArrowRightShort className={styles.arrow} aria-label="go-arrow" role="img" />
          </div>
          <div className={styles.articleInfoContainer}>
            <p className={styles.info}>
              {new Date(article.date || article._createdAt).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </p>
            <p className={styles.info}>
              {getEstimatedReadingTimeInMinutes(getPortableTextAsString(article._rawContent))} min
              read
            </p>
          </div>
        </div>
      </SmartLink>
    );
  }

  return (
    <ModuleLayout
      id={moduleId}
      className={className}
      currentModuleBgColor={getModuleBgColor(props)}
      previousModuleBgColor={previousModuleBgColor}
      childrenClassName={styles.content}
    >
      <div className={styles.contentContainer}>
        {articles.length === 1 && articles.map((article, i) => renderArticle(article, i))}
        {articles.length === 2 && (
          <div className={styles.columnContainer}>
            {articles.map((article, i) => renderArticle(article, i))}
          </div>
        )}
        {articles.length === 3 && (
          <>
            <div className={styles.rowContainer}>
              {articles.map((article, i) => i === 0 && renderArticle(article, i))}
            </div>
            <div className={styles.columnContainer}>
              {articles.map((article, i) => (i === 1 || i === 2) && renderArticle(article, i))}
            </div>
          </>
        )}
      </div>
    </ModuleLayout>
  );
}

export default FeaturedArticlesModule;
